import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { StudentPaymentEnum } from '@/utils/enums/StudentPaymentEnum';
import { Currency } from '@/services/vaultCashes/types';
import type { RootState } from '@/store';

interface StudentPaymentEntity {
  id: string;
  feeType: StudentPaymentEnum;
  amount?: number;
  discountedAmount?: number;
  currency?: Currency;
}

const studentPaymentAdapter = createEntityAdapter<StudentPaymentEntity>();

const initialState = studentPaymentAdapter.getInitialState<{
  currency: Currency | string;
  loading: boolean;
  error: string | null;
}>({
  currency: '',
  loading: false,
  error: null,
});

const studentPaymentSlice = createSlice({
  name: 'studentPayment',
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    addFee: (state, action) => {
      studentPaymentAdapter.addOne(state, {
        id: action.payload,
        feeType: action.payload,
      });
    },
    removeFee: (state, action) => {
      studentPaymentAdapter.removeOne(state, action.payload);
    },
  },
});

export const {
  selectAll: selectAllFees,
  selectById: selectFeeById,
  selectIds: selectFeeIds,
} = studentPaymentAdapter.getSelectors<RootState>(
  (state) => state.studentPayment
);

export const { setCurrency, addFee, removeFee } = studentPaymentSlice.actions;

export default studentPaymentSlice.reducer;
