/* eslint-disable no-console */
/* eslint-disable import/no-mutable-exports */
import { useState, createContext, useContext, useMemo } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { APIOptions, PrimeReactProvider, addLocale } from 'primereact/api';
// import * as Sentry from '@sentry/react';
import { classNames } from 'primereact/utils';
import App from './App';
import { store } from './store';
import './index.css';
import '@/locales';
import 'primeicons/primeicons.css';

addLocale('tr', {
  firstDayOfWeek: 1,
  dayNames: [
    'Pazar',
    'Pazartesi',
    'Salı',
    'Çarşamba',
    'Perşembe',
    'Cuma',
    'Cumartesi',
  ],
  dayNamesShort: ['Paz', 'Pzt', 'Salı', 'Çrş', 'Prş', 'Cuma', 'Cts'],
  dayNamesMin: ['Paz', 'Pzt', 'Salı', 'Çrş', 'Prş', 'Cuma', 'Cts'],
  monthNames: [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ],
  monthNamesShort: [
    'Oca',
    'Şub',
    'Mar',
    'Nis',
    'May',
    'Haz',
    'Tem',
    'Ağu',
    'Eyl',
    'Eki',
    'Kas',
    'Ara',
  ],
  today: 'Bugün',
  clear: 'Temizle',
  emptyMessage: 'Kayıt bulunamadı',
  now: 'Şimdi',
});

const primeThemeValue: Partial<APIOptions> = {
  ripple: true,
  locale: 'tr',
  pt: {
    datatable: {
      loadingOverlay: {
        className: classNames(
          'fixed w-full h-full t-0 l-0 bg-gray-100/40',
          'transition duration-200',
          'absolute flex items-center justify-center z-2',
          'dark:bg-gray-950/40' // Dark Mode
        ),
      },
    },
  },
};

// TODO: open this to init sentry
// if (import.meta.env.VITE_ENV === 'prod') {
//   Sentry.init({
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration(),
//     ],
//     tracesSampleRate: 1.0,
//     tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//     beforeSend(event) {
//       // we can add any add additioanl flags or context here
//       return event;
//     },
//   });
// }

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(
      () => {
        // Service Worker başarıyla kaydedildi
      },
      (error) => {
        console.error('Service Worker registration failed:', error);
      }
    );
  });
}

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
  prompt(): Promise<void>;
}

export const InstallPromptContext = createContext<{
  deferredPrompt: BeforeInstallPromptEvent | null;
  setDeferredPrompt: (event: BeforeInstallPromptEvent | null) => void;
}>({
  deferredPrompt: null,
  setDeferredPrompt: () => {},
});

export const useInstallPrompt = () => useContext(InstallPromptContext);

function AppWrapper() {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);

  window.addEventListener('beforeinstallprompt', (event: Event) => {
    const promptEvent = event as BeforeInstallPromptEvent;
    setDeferredPrompt(promptEvent);
  });

  const contextValue = useMemo(
    () => ({
      deferredPrompt,
      setDeferredPrompt,
    }),
    [deferredPrompt]
  );

  return (
    <Provider store={store}>
      <InstallPromptContext.Provider value={contextValue}>
        <PrimeReactProvider value={primeThemeValue}>
          <App />
        </PrimeReactProvider>
      </InstallPromptContext.Provider>
    </Provider>
  );
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AppWrapper />
);
