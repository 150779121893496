import {
  createSlice,
  createEntityAdapter,
  EntityId,
  PayloadAction,
} from '@reduxjs/toolkit';
import { ISelectedQuestion } from '@/services/liveExams/types';
import type { RootState } from '@/store';

interface QuestionWithId extends ISelectedQuestion {
  id: EntityId;
}

const liveExamQuestionsAdapter = createEntityAdapter<QuestionWithId>();
const initialState = liveExamQuestionsAdapter.getInitialState();

const liveExamQuestionsSlice = createSlice({
  name: 'liveExamQuestions',
  initialState,
  reducers: {
    addQuestion: (state, action: PayloadAction<ISelectedQuestion>) => {
      const id =
        action.payload.questionPoolID || action.payload.examQuestionID || '';
      liveExamQuestionsAdapter.addOne(state, {
        ...action.payload,
        id,
      });
    },
    setQuestions: (state, action: PayloadAction<ISelectedQuestion[]>) => {
      const questionsWithIds = action.payload.map((question) => ({
        ...question,
        id: question.questionPoolID || question.examQuestionID || '',
      }));
      liveExamQuestionsAdapter.setAll(state, questionsWithIds);
    },
    removeQuestion: (state, action: PayloadAction<string>) => {
      liveExamQuestionsAdapter.removeOne(state, action.payload);
    },
    clearQuestions: liveExamQuestionsAdapter.removeAll,
  },
});

export const {
  selectAll: selectAllQuestions,
  selectById: selectQuestionById,
  selectIds: selectQuestionIds,
  selectTotal: selectTotalQuestions,
} = liveExamQuestionsAdapter.getSelectors<RootState>(
  (state) => state.liveExamQuestions
);

export const selectSelectedQuestions = (state: RootState) =>
  selectAllQuestions(state);

export const { addQuestion, setQuestions, removeQuestion, clearQuestions } =
  liveExamQuestionsSlice.actions;

export default liveExamQuestionsSlice.reducer;
