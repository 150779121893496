/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import OneSignal from 'react-onesignal';
import { isMobile } from '@/utils/helpers/common';
import { isProduction } from '@/utils/constants/common';
import { useInstallPrompt } from '@/main';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
  prompt(): Promise<void>;
}

const PWAInfoBox: React.FC = () => {
  const { deferredPrompt, setDeferredPrompt } = useInstallPrompt();
  const [isVisible, setIsVisible] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [notificationPermission, setNotificationPermission] =
    useState<string>('default');

  // Bildirim izni kontrolü
  const checkNotificationPermission = async () => {
    try {
      const currentPermission = await OneSignal.Notifications.permission;
      setNotificationPermission(currentPermission ? 'granted' : 'default');

      // Bildirim izni değişikliklerini dinle
      OneSignal.Notifications.addEventListener(
        'permissionChange',
        (newPermission: boolean) => {
          setNotificationPermission(newPermission ? 'granted' : 'default');
        }
      );
    } catch (error) {
      console.error('OneSignal permission check failed:', error);
    }
  };

  useEffect(() => {
    if (import.meta.env.VITE_ONESIGNAL_APP_ID) {
      // OneSignal'ı başlat
      OneSignal.init({
        appId: import.meta.env.VITE_ONESIGNAL_APP_ID || '',
        allowLocalhostAsSecureOrigin: !isProduction,
        serviceWorkerPath:
          '/pushNotification/pushNotificationServiceWorkere12.js',
      }).then(() => {
        checkNotificationPermission();
      });
    }
  }, []);

  useEffect(() => {
    // PWA modunda çalışıp çalışmadığını kontrol et
    const isPWA =
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any).standalone ||
      document.referrer.includes('android-app://');

    // PWA yükleme durumunu localStorage'dan kontrol et
    const isPWAInstalled = localStorage.getItem('isPWAInstalled') === 'true';

    // "Bir daha gösterme" durumunu kontrol et
    const hidePrompt = localStorage.getItem('hidePWAModal') === 'true';

    // iOS kontrolü
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
    setIsIOS(isIOSDevice);

    // PWA modunda çalışıyorsa ve daha önce kaydedilmemişse kaydet
    if (isPWA && !isPWAInstalled) {
      localStorage.setItem('isPWAInstalled', 'true');
    }

    // Mobil cihazda ve PWA modunda değilse, yüklenmemişse ve "bir daha gösterme" seçilmemişse göster
    setIsVisible(
      isMobile && !isPWA && !isPWAInstalled && !hidePrompt && !isProduction
    );

    // PWA kurulum promptunu yakala
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as BeforeInstallPromptEvent);
    };

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, [deferredPrompt, setDeferredPrompt]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted' || outcome === 'dismissed') {
        // Kullanıcı PWA'yı yüklediğinde localStorage'a kaydet
        localStorage.setItem('isPWAInstalled', 'true');
        setDeferredPrompt(null);
        setIsVisible(false);

        // PWA yüklendikten sonra bildirim izni iste
        if (!isIOS && notificationPermission === 'default') {
          try {
            await OneSignal.Notifications.requestPermission();
          } catch (error) {
            console.error('OneSignal prompt failed:', error);
          }
        }
      }
    }
  };

  const handleHidePrompt = () => {
    localStorage.setItem('hidePWAModal', 'true');
    setIsVisible(false);
  };

  // Bildirim izni isteme fonksiyonu
  const requestNotificationPermission = async () => {
    if (notificationPermission === 'default') {
      try {
        await OneSignal.Notifications.requestPermission();
      } catch (error) {
        console.error('OneSignal prompt failed:', error);
      }
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed top-5 left-1/2 -translate-x-1/2 w-[90%] max-w-[400px] bg-white rounded-xl shadow-lg z-[1000] p-4 animate-slide-in border border-gray-100">
      <div className="relative">
        <h3 className="text-lg font-semibold text-gray-800 mb-2">
          E12 Mobil Uygulamasını Yükleyin
        </h3>
        <p className="text-sm text-gray-600 mb-3">
          {isIOS
            ? 'Daha iyi bir deneyim için E12 uygulamasını ana ekranınıza ekleyin.'
            : 'Daha iyi bir deneyim için E12 uygulamasını cihazınıza yükleyin.'}
        </p>

        <div className="bg-gray-50 p-3 rounded-lg">
          {!isIOS && (
            // Android için yükleme butonu ve talimatlar
            <>
              <button
                onClick={handleInstallClick}
                className="w-full bg-primary-color text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity mb-3"
                type="button"
                disabled={!deferredPrompt}
              >
                Uygulamayı Yükle
              </button>
              {notificationPermission === 'default' && (
                <button
                  onClick={requestNotificationPermission}
                  className="w-full bg-white border border-primary-color text-primary-color py-2 px-4 rounded-lg hover:bg-gray-50 transition-colors mb-3"
                  type="button"
                >
                  Bildirimlere İzin Ver
                </button>
              )}
              <div className="border-t border-gray-200 pt-3">
                <p className="text-sm text-gray-700 mb-2">
                  Eğer manuel olarak yüklemek isterseniz:
                </p>
                <ol className="ml-5 list-decimal">
                  <li className="text-sm text-gray-600 mb-1.5">
                    Chrome menüsünü açın (⋮)
                  </li>
                  <li className="text-sm text-gray-600">
                    &quot;Ana ekrana ekle&quot; seçeneğine dokunun
                  </li>
                </ol>
              </div>
            </>
          )}
          {isIOS && (
            // iOS için manuel yükleme talimatları
            <>
              <p className="text-sm text-gray-700 mb-2">
                Ana ekrana eklemek için:
              </p>
              <ol className="ml-5 list-decimal">
                <li className="text-sm text-gray-600 mb-1.5">
                  Kullandığınız tarayıcıda Paylaş butonuna dokunun
                </li>
                <li className="text-sm text-gray-600 mb-1.5">
                  Açılan menüde &quot;Ana Ekrana Ekle&quot; seçeneğine dokunun
                </li>
              </ol>
            </>
          )}
        </div>

        <div className="mt-3 text-center">
          <button
            type="button"
            onClick={handleHidePrompt}
            className="text-sm text-gray-500 hover:text-gray-700 hover:underline"
          >
            Bunu bir daha gösterme
          </button>
        </div>

        <button
          type="button"
          onClick={() => setIsVisible(false)}
          aria-label="Kapat"
          className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors flex items-center justify-center text-gray-600 text-sm"
        >
          ✕
        </button>
      </div>
    </div>
  );
};

export default PWAInfoBox;
