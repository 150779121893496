import { getAccessToken } from '@/utils/helpers/authHelpers';
import { parseAuthToken } from '@/utils/helpers/token';
import { Operations } from '../enums/Operations';
import { hasPermission } from './userPermission';
import { isProduction } from '@/utils/constants/common';

export const checkE12Admin = () => {
  const parsedAuthToken = parseAuthToken(getAccessToken());
  return parsedAuthToken?.managementRoles?.length > 0;
};

export const checkTeacher = () => {
  const parsedAuthToken = parseAuthToken(getAccessToken());
  return parsedAuthToken?.roles?.some((role) => role?.values.includes(15));
};

// That makes it visible only in the stage env or by E12 Admin
export const mockModuleVisibility = () => {
  return hasPermission(Operations.GetAllPermissions) || !isProduction;
};
